.font-title {
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 100%;
  color: #3e4957;
}

.font-text {
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #979797;
}

.btn {
  background: #631ca0 !important;
  border-radius: 10px !important;
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 100% !important;
  text-transform: uppercase !important;
  color: #ffffff !important;
  padding: 18px !important;
  max-width: 288px !important;
  width: 100% !important;
}

.background-order-block {
  background: #e8ddf1;
  opacity: 0.5;
}

.background-reservation-block {
  background: #e8ddf1;
  opacity: 0.5;
}

.font-text-home-page {
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #979797 !important;
}
