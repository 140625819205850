.text-cart {
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 30px !important;
  line-height: 100% !important;
  color: #3e4957 !important;
}

.btn-order {
  background: #631ca0 !important;
  border-radius: 10px !important;
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 100% !important;
  text-align: center !important;
  text-transform: uppercase !important;
  color: #ffffff !important;
  padding: 20px !important;
}

.text-link {
  text-decoration: none !important;
}

.block-cart {
  background: #f8f8f8 !important;
  border-top: 0.5px solid #c0c0c0 !important;
  box-sizing: border-box !important;
  border-radius: 30px 30px 0px 0px !important;
  margin-top: 40px !important;
  padding-bottom: 30px !important;
  width: 100% !important;
  bottom: 0;
  max-width: 450px !important;
}

.block-cart-flex {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 0 20px !important;
}

.container-block {
  margin-top: 30px !important;
  color: #3e4957 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.btn-edit {
  background: #ffffff !important;
  border: 0.5px solid #c0c0c0 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 100% !important;
  text-align: center !important;
  text-transform: uppercase !important;
  color: #3e4857 !important;
  padding: 7px !important;
}

.block-background {
  background: #e8ddf1 !important;
  border-radius: 6px !important;
  text-align: center !important;
  color: #631ca0 !important;
  min-width: 120px !important;
  padding: 10px !important;
  margin-top: 20px !important;
  width: 115px !important;
  margin-left: 20px !important;
}

.btn-add {
  background: #631ca0;
  border-radius: 6px !important;
  width: 30px;
  color: white !important;
  min-width: 30px !important;
  margin-left: 10px !important;
}
