.font-title {
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 100%;
  color: #3e4957;
}

.menu-item {
  background: #ffffff;
  border: 0.5px solid #c0c0c0;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.btn-remove {
  background: #d6d6d6 !important;
  border-radius: 6px !important;
  width: 30px !important;
  color: white !important;
  min-width: 30px !important;
  margin-right: 10px !important;
  padding: 3px 10px !important;
}

.btn-remove-count {
  background: #631ca0;
  border-radius: 6px !important;
  width: 30px !important;
  color: white !important;
  min-width: 30px !important;
  margin-right: 10px !important;
  padding: 3px 10px !important;
}

.block-weight {
  border: 0.5px solid #c0c0c0 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  padding: 5px !important;
  max-width: 40px !important;
  width: 100% !important;
  margin-bottom: 10px !important;
  text-align: center !important;
}

.menu-text-block {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  padding-left: 6px !important;
}

.right-side-block {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-end !important;
  justify-content: space-between !important;
}

.bold-text {
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #3e4857 !important;
}

.opacity-text {
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 100% !important;
  text-transform: capitalize !important;
  color: #c0c0c0 !important;
}

.button-choose {
  background: #e8ddf1;
  border-radius: 6px !important;
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 100% !important;
  text-align: center !important;
  text-transform: uppercase !important;
  color: #631ca0 !important;
  padding: 10px !important;
}

.button-see-more {
  border: 1px solid #631ca0 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 100% !important;
  text-align: center !important;
  text-transform: uppercase !important;
  color: #631ca0 !important;
  padding: 15px !important;
  width: 270px !important;
}

.block-category-menu {
  background: #ffffff;
  border: 0.5px solid #c0c0c0 !important;
  box-sizing: border-box !important;
  border-radius: 20px !important;
  padding: 10px !important;
  width: 90% !important;
  max-width: 100px !important;
  height: 100px !important;
}

.text-link {
  text-decoration: none !important;
  color: inherit !important;
}

.slick-prev,
.slick-next {
  position: relative !important;
}

.slick-next:before {
  content: "" !important;
}

.btn-choose {
  background: #e8ddf1 !important;
  border-radius: 6px !important;
  padding: 8px 12px !important;
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 100% !important;
  text-align: center !important;
  text-transform: uppercase !important;
  color: #631ca0 !important;
  min-width: 80px !important;
}

.btn-add {
  background: #631ca0;
  border-radius: 6px !important;
  width: 30px;
  color: white !important;
  min-width: 30px !important;
  margin-left: 10px !important;
  padding: 3px 10px !important;
}

.btn-add-modal {
  background: #e8ddf1;
  border-radius: 6px !important;
  width: 30px;
  color: #631ca0 !important;
  min-width: 30px !important;
  margin-left: 10px !important;
  padding: 3px 10px !important;
}

.css-1d9gg9l-MuiSvgIcon-root {
  font-size: 1rem !important;
  color: #631ca0 !important;
}

.css-csyk4v {
  font-size: 1rem !important;
  color: #631ca0 !important;
}

.css-oidp21-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #631ca0 !important;
}

.css-euxvpt-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #631ca0 !important;
}
