.text-order {
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 700;
  font-size: 30px;
  line-height: 100% !important;
  color: #3e4957 !important;
}

.btn-order-add {
  background: #e8ddf1 !important;
  border-radius: 10px !important;
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  color: #ffffff !important;
  padding: 16px 20px !important;
}

.block-order-count {
  background: #ffffff;
  border-top: 0.5px solid #c0c0c0;
  border-left: 0.5px solid #c0c0c0;
  border-bottom: 0.5px solid #c0c0c0;
  box-sizing: border-box;
  border-radius: 15px;
  width: 73px;
  height: 45px;
}

.block-payment-order {
  background: #ffffff;
  border: 0.5px solid #c0c0c0;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 94px;
  max-height: 86px;
  width: 100%;
  height: 100%;
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  line-height: 100% !important;
  text-align: center !important;
  color: #3e4857 !important;
  margin: 0 15px !important;
}

.block-order-finish {
  background: #f8f8f8;
  border-top: 0.5px solid #c0c0c0;
  box-sizing: border-box;
  border-radius: 30px 30px 0px 0px;
}

.btn-edit {
  background: #631ca0 !important;
  border: 0.5px solid #c0c0c0 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 100% !important;
  text-align: center !important;
  text-transform: uppercase !important;
  color: white !important;
  padding: 7px !important;
}

.block-menu-order {
  background: #ffffff;
  border: 0.5px solid #c0c0c0;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 10px;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #631ca0 !important;
}

.css-1a5icme.Mui-checked {
  color: #631ca0 !important;
}

.css-1m20j1c-MuiButtonBase-root-MuiTab-root.Mui-selected {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  color: #3e4957 !important;
  text-transform: capitalize;
}

.css-1m20j1c-MuiButtonBase-root-MuiTab-root {
  font-family: "Arial" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 100% !important;
  text-align: center !important;
  color: #aeaeb2 !important;
  text-transform: capitalize !important;
}

.css-149ymfv.Mui-selected {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  color: #3e4957 !important;
  text-transform: capitalize;
}

.css-1v4ccyo {
  border-radius: 10px !important;
}

.css-1hof3tc {
  border-radius: 10px !important;
}

.css-149ymfv {
  font-family: "Arial" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 100% !important;
  text-align: center !important;
  color: #aeaeb2 !important;
  text-transform: capitalize !important;
}

.css-11yukd5-MuiTabs-indicator {
  height: 3px !important;
}

.css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root {
  background-color: green !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 100% !important;
  border-radius: 10px !important;
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 100% !important;
  border-radius: 10px !important;
}

.css-17yyoxz-MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: #631ca0 !important;
}

.css-1yyanpp-MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: #979797 !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  color: #979797 !important;
}

.css-1ap1up .MuiFormControlLabel-label {
  color: #631ca0 !important;
}

.css-9l3uo3 {
  color: #979797 !important;
}
.css-1a5icme {
  color: #979797 !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #631ca0 !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #631ca0 !important;
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #631ca0 !important;
}

.css-1hof3tc.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #631ca0 !important;
}

.css-u9osun.Mui-focused {
  color: #631ca0 !important;
}

.css-1v4ccyo.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #631ca0 !important;
}

@media (max-width: 360px) {
  .media-order-placement {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
}
