body {
  margin: 0px !important;
}

.font-text {
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  color: #3e4857 !important;
}

.opacity-text {
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 100% !important;
  text-transform: inherit !important;
  color: #c0c0c0 !important;
}

.block-weight {
  border: 0.5px solid #c0c0c0 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  padding: 5px !important;
  max-width: 50px !important;
  margin-bottom: 10px !important;
  text-align: center !important;
}

.btn-remove {
  background: #d6d6d6 !important;
  border-radius: 6px !important;
  width: 30px !important;
  color: white !important;
  min-width: 30px !important;
  margin-right: 10px !important;
}

.btn-add-cart {
  background: #631ca0 !important;
  border-radius: 10px !important;
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 100% !important;
  text-align: center !important;
  text-transform: uppercase !important;
  color: #ffffff !important;
  padding: 15px !important;
}

.text-price {
  font-family: "Arial" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 100% !important;
  text-align: center !important;
  color: #3e4857 !important;
}

.text-link {
  text-decoration: none !important;
}

.css-13xqbvb {
  background-color: red !important;
}

.css-106c1u2-MuiBadge-badge {
  background-color: red !important;
}
